import { DEFAULT_DIALOG_CONFIG, DIALOG_DATA } from '@angular/cdk/dialog';
import { CdkDrag, CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	Inject,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, startWith, take, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { FileUploadService } from '@yuno/admin/features/file-upload';
import { TextfieldFacade } from '@yuno/admin/features/textfield-pages';
import {
	AnnotationComponent,
	DragRowComponent,
	UserRightsMessageComponent,
	YunoAdminButtonsModule,
	YunoAdminNavbarButtonsComponent,
	YunoCardModule
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { DialogType, FileFormatTypes } from '@yuno/angular/api';
import { YunoFormsModule, moveItemInFormArray } from '@yuno/angular/forms';
import { App, Config } from '@yuno/api/interface';
import { YunoButtonComponent } from '@yuno/project-atlas/ui';

import { InternalViewService } from './internal-view.service';

export interface DialogData {
	[key: string]: unknown;
}

@Component({
	selector: 'yuno-admin-app-configuration-upload-dialog',
	standalone: true,
	imports: [CommonModule],
	template: `
		<div class="flex flex-col gap-4 p-4">
			I close on background click
			<code>
				<pre class="text-xs">{{ data | json }}</pre>
			</code>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
				max-height: 80vh;
				overflow: auto;
			}
		`
	]
})
class AppConfigurationUploadDialogComponent {
	constructor(@Inject(DIALOG_DATA) public data: DialogData) {}
}

@Component({
	standalone: true,
	imports: [
		CommonModule,
		YunoCardModule,
		ReactiveFormsModule,
		YunoAdminButtonsModule,
		YunoFormsModule,
		DragDropModule,
		DragRowComponent,
		UserRightsMessageComponent,
		AnnotationComponent,
		YunoButtonComponent,
		YunoAdminNavbarButtonsComponent,
		MatDialogModule,
		CdkDrag
	],
	providers: [
		InternalViewService,
		{ provide: DEFAULT_DIALOG_CONFIG, useValue: { hasBackdrop: true } }
	],
	selector: 'yuno-admin-app-configuration',
	templateUrl: './internal-view.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppConfigurationComponent extends AppDataComponent implements OnInit, OnDestroy {
	private dialogRef: MatDialogRef<AppConfigurationUploadDialogComponent>;

	readonly service = inject(InternalViewService);
	private readonly dialog = inject(MatDialog);
	private readonly appFacade = inject(AppFacade);
	private readonly textfieldFacade = inject(TextfieldFacade);
	private readonly destroyRef = inject(DestroyRef);
	private readonly fileUploadService = inject(FileUploadService);

	textfields$ = this.textfieldFacade.allTextfields$.pipe(
		startWith([]),
		tap(data => {
			if (!data) {
				return;
			}
			this.service.textfieldsSelect = data.map(object => object._id) as string[];
			this.service.textfieldsDisplay = data.map(object => object.id) as string[];
		})
	);

	hover: number | null = 0;

	ngOnInit(): void {
		this.textfieldFacade.get();
		this.onChanges();

		const app = this.appFacade.$app();
		const config = this.appFacade.$config();

		if (!app || !config) {
			return;
		}

		this.service.appForm.patchValue(app);
		// @ts-ignore partial language error
		this.service.configForm.patchValue(config);
		const languages = config.languages;
		if (languages && languages.length > 0) {
			for (const lang of languages) {
				this.service.addLanguage(lang);
			}
		}
		if (!languages || languages.length <= 0) {
			this.service.addLanguage(config.language);
		}

		this.parseUrls();

		!this.isMinimalEditor && this.disableForms();
	}

	onChanges(): void {
		this.service.appForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300))
			.subscribe(() => this.appFacade.updateApp(this.service.appForm.getRawValue() as App));

		this.service.configForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300))
			.subscribe(config => {
				this.appFacade.updateSettings(this.service.configForm.getRawValue() as Config);
				if (config?.interface?.buttonStyle) {
					const btn = config.interface.buttonStyle;
					const classes = ['rounded', 'squared'];

					// Remove all classes
					document.body.classList.remove(...classes);

					// Add the appropriate class based on the button clicked
					if (btn === 'square') {
						document.body.classList.add('squared');
					} else if (btn === 'rounded') {
						document.body.classList.add('rounded');
					}
				}
			});

		this.service.languages.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300))
			.subscribe(languages => {
				if (languages.length > 0 && languages[0]) {
					const language = this.service.configForm.get('language');
					if (language) {
						language.setValue(languages[0]);
					}
				}
			});
	}

	disableForms(): void {
		this.service.appForm.disable();
		this.service.configForm.disable();
	}

	parseUrls(): void {
		const urls: string[] = this.route.snapshot.data['app'].url;
		for (const url of urls) {
			this.service.addUrl(url);
		}
	}

	/* CDK DragList Drop Event */
	drop(event: CdkDragDrop<FormArray<FormControl<string>>>): void {
		moveItemInFormArray(this.service.languages, event.previousIndex, event.currentIndex);
	}

	getMatomoUrl(): string {
		const trackingId = this.service.trackingId.value;
		return `${this.environment['yuno-matomo-token']}/index.php?module=CoreHome&action=index&date=yesterday&period=day&idSite=${trackingId}`;
	}

	onAdd(): void {
		this.service.addNewUrl();
	}

	onRemove(index: number): void {
		this.service.removeUrl(index);
	}

	onSave() {
		this.appFacade.saveSettings();
	}

	openFileUpload(): void {
		const data: DialogType = {
			appId: this.appId,
			type: FileFormatTypes.yuno,
			filesizeLimit: 50
		};

		this.fileUploadService.openDialog(
			data,
			`${this.environment['yuno-tools-url']}/uploader/upload_datetime/${this.appId}`
		);

		this.fileUploadService.response.pipe(take(1)).subscribe(event => {
			this.openDialogRef(event);
		});
	}

	openDialogRef(event: HttpResponse<never>) {
		this.dialogRef = this.dialog.open(AppConfigurationUploadDialogComponent, {
			data: event.body
		});
	}

	ngOnDestroy() {
		const classes = ['rounded', 'squared'];
		document.body.classList.remove(...classes);
	}
}
