import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { UsersFacade } from '@yuno/admin/features/users';
import { AddUserComponent } from '@yuno/admin/features/users/feature/add-user/add-user.component';
import { UserDataComponent } from '@yuno/admin/features/users/feature/user-data/user-data.component';
import {
	TableRow,
	YunoAdminButtonComponent,
	YunoAdminButtonTableComponent,
	YunoAdminTableComponent,
	YunoCardModule
} from '@yuno/admin/ui';
import { AuthFacade } from '@yuno/angular-auth';
import { DialogItem, MessageDialogComponent } from '@yuno/angular/notifications';
import { SafeUser, UserRoles, UserRolesEnum } from '@yuno/api/interface';

@Component({
	selector: 'yuno-admin-users',
	standalone: true,
	imports: [
		YunoCardModule,
		YunoAdminTableComponent,
		YunoAdminButtonComponent,
		AsyncPipe,
		YunoAdminButtonTableComponent
	],
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersComponent implements OnInit {
	private readonly route = inject(ActivatedRoute);
	private readonly auth = inject(AuthFacade);
	private readonly userFacade = inject(UsersFacade);
	private readonly dialog = inject(MatDialog);

	readonly roles = UserRolesEnum;

	users$ = this.userFacade.users$;
	dialogUserDataRef: MatDialogRef<UserDataComponent>;
	dialogNewUserRef: MatDialogRef<AddUserComponent>;

	dialogRef: MatDialogRef<MessageDialogComponent>;

	get userRole(): UserRolesEnum {
		return this.route.snapshot.data['userRole'] as UserRoles;
	}

	ngOnInit(): void {
		this.userFacade.getUsers();
	}

	onSelect(e: TableRow) {
		if (!e) {
			return;
		}

		const user = e as SafeUser;
		if (user?.displayName?.includes('Deleted')) {
			return;
		}

		this.dialogUserDataRef = this.dialog.open(UserDataComponent, {
			data: user as SafeUser
		});
	}

	onSignUser() {
		this.dialogNewUserRef = this.dialog.open(AddUserComponent);
	}

	onDelete(data: unknown): void {
		if (!data) {
			return;
		}

		const user = data as SafeUser;
		const dialogData: DialogItem = {
			title: 'Confirm',
			message: `Are you sure you want to remove the user, <b>${user.displayName}</b>, email: <b>${user.email}</b>?`,
			buttons: [
				{
					key: 'Delete',
					type: 'danger',
					confirm: true
				},
				{
					key: 'Cancel'
				}
			],
			confirm: 'Confirmed'
		};

		this.dialogRef = this.dialog.open(MessageDialogComponent, {
			data: dialogData
		});

		this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			console.log(confirmed);
			if (confirmed) {
				this.userFacade.deleteUser(user);
				// 	this.dashboardFacade.removeDashboardItem(index);
				// 	this.dashboardFacade.save();
			}
		});
	}

	minimalRole(minimal: UserRoles, role?: UserRolesEnum): boolean {
		console.log(this.userRole);
		return this.auth.userHasMinimalRole(minimal, role || this.userRole);
	}
}
