import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { YunoFormsModule } from '@yuno/angular/forms';

import { TextEditorsService } from '../text-editors.service';


@Component({
	selector: 'yuno-admin-textfield-legend-editor',
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule, AsyncPipe],
	templateUrl: './legend-editor.component.html',
	styleUrls: ['./legend-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendEditorComponent implements OnInit, OnDestroy {
	readonly editService = inject(TextEditorsService);

	service$ = this.editService.getActiveService();
	data$ = this.editService.data$;

	ngOnInit() {
		this.editService.onInitLegends();
	}

	ngOnDestroy() {
		this.editService.onClearLegend();
	}
}
