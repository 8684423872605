<div
	class="md:toggled absolute z-[120] mr-0 block h-full w-96 border-l border-yuno-gray-200 pr-0 shadow-xl transition-all duration-500 ease-in-out md:relative md:w-64 md:shadow-none"
	[ngClass]="{ '-mr-96 md:-mr-64': !opened }">
	<!--	[ngClass]="{ '-mr-96 md:-mr-64 md:ml-12': !opened }" -->
	<button
		class="absolute left-0 top-2 flex h-12 w-14 transform items-center justify-start rounded-lg bg-white text-gray-400 transition-transform duration-500 ease-in-out"
		[ngClass]="{ 'h-10 -translate-x-12 bg-yuno-gray-700 text-white': !opened }"
		(click)="toggleOpened()">
		@if (opened) {
			<!-- Close Icon -->
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="ml-4 h-4 w-4 scale-x-[-1] transform"
				viewBox="0 0 20 20"
				fill="currentColor">
				<path
					fill-rule="evenodd"
					d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
					clip-rule="evenodd" />
			</svg>
		}

		@if (!opened) {
			<!-- Open Icon -->
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="ml-3 h-8 w-8"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor">
				<use href="#ui-dots" />
			</svg>
		}
	</button>

	@if (data$ | async; as data) {
		<div class="flex h-full flex-grow flex-col overflow-y-auto bg-white">
			<div class="flex h-16 items-center justify-start gap-4 px-4"></div>
			<div class="flex flex-1 flex-col overflow-y-auto px-4">
				@if (sideOptionsTemplate) {
					<div class="relative flex-1 space-y-1 bg-white">
						<ng-container *ngTemplateOutlet="sideOptionsTemplate"></ng-container>
					</div>
				}
			</div>
			<div class="mb-4 flex flex-col items-center px-4"></div>
		</div>
	}
</div>
