import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { EventData, NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { Point } from 'geojson';
import { LngLat, Map, MapLibreEvent } from 'maplibre-gl';
import { combineLatest, debounceTime, startWith, tap } from 'rxjs';

import { MarkerCategoriesFacade } from '@yuno/admin/features/place-markers/data-access';
import { MarkerEvent } from '@yuno/api/interface';
import { waitFor } from '@yuno/libs/shared/helpers';
import { MarkersModule } from '@yuno/project-atlas/ui';

import { MapViewerService } from './map-viewer.service';


@Component({
	selector: 'yuno-admin-place-markers-map-viewer',
	standalone: true,
	imports: [NgxMapLibreGLModule, MarkersModule, AsyncPipe],
	templateUrl: './map-viewer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceMarkersMapViewerComponent {
	readonly service = inject(MapViewerService);
	private readonly categoryFacade = inject(MarkerCategoriesFacade);
	private readonly route = inject(ActivatedRoute);
	private readonly router = inject(Router);
	private readonly destroyRef = inject(DestroyRef);
	private readonly cdr = inject(ChangeDetectorRef);

	zoom = this.service.zoom;
	center = this.service.center;
	pitch = this.service.pitch;
	bearing = this.service.bearing;

	data$ = combineLatest({
		language: this.service.language$,
		style: this.service.data$,
		bounds: this.service.bounds$,
		styleBounds: this.categoryFacade.mapStyleBounds$.pipe(
			startWith(undefined),
			tap(data => {
				data && !this.service.hasBounds && this.service.setBounds(data);
			})
		),
		boundsOptions: this.service.boundsOptions$,
		draggable: this.service.draggable$
	});

	mapStyle$ = this.categoryFacade.mapStyle$;

	async mapLoad(map: Map): Promise<void> {
		this.service.setMap(map);
		await waitFor(1500);
		this.service.animateBounds();
		this.onChanges();
	}

	moveEnd(
		event: MapLibreEvent<MouseEvent | TouchEvent | WheelEvent | undefined> & EventData
	): void {
		this.service.setMapUrl(event);
	}

	dragEnd(lngLat: LngLat): void {
		this.service.setDragend(lngLat);
	}

	markerMouseClickEvent(events: MarkerEvent): void {
		const id = events.feature?.properties?.['id'] as string;
		if (!id) {
			return;
		}

		this.categoryFacade.selectMarker(id);
		this.router.navigate(['./', 'map', 'marker', id], { relativeTo: this.route });
	}

	onChanges(): void {
		this.service.sideOptionsForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.cdr.detectChanges();
			});
	}

	getMarkers(
		markers: {
			geometry: Point;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			data: any;
		}[],
		zoom: number
	) {
		if (this.service.getZoomSettings()?.value) {
			return markers.filter(
				marker => zoom >= marker.data.minZoom && zoom <= marker.data.maxZoom
			);
		}
		return markers;
	}
}
