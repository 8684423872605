@if (form) {
	<form [formGroup]="form">
		<yuno-forms-title>Map Style</yuno-forms-title>
		<div formArrayName="mapStyles" class="my-2 flex flex-col items-end gap-2">
			<div
				class="grid grid-cols-1 gap-2"
				cdkDropList
				(cdkDropListDropped)="dragDropMapStyles($event)">
				@for (style of service.mapStyles.controls; track style; let i = $index) {
					<div cdkDrag>
						<yuno-admin-drag-row [disableHeight]="true">
							<div class="flex flex-row items-center gap-4" title>
								<div
									[formArrayName]="i"
									class="grid grid-cols-1 items-center justify-center gap-2">
									<div formGroupName="style">
										<yuno-forms-select
											formControlName="_id"
											[selectValues]="service.styleValues"
											[display]="service.styleDisplay"></yuno-forms-select>
									</div>

									<!-- LANGUAGE -->
									<section formGroupName="description">
										@for (lang of languages; track lang) {
											@if (lang === this.language) {
												<yuno-forms-text
													[language]="lang"
													[formControlName]="lang"
													placeholder="Add description"></yuno-forms-text>
											}
										}
									</section>
								</div>
								<button
									yuno-admin-delete-admin-button
									(click)="removeMapStyle(i)"></button>
							</div>
						</yuno-admin-drag-row>
					</div>
				}
			</div>
			<button yuno-admin-button [add]="true" (click)="addMapStyle()">Add map style</button>
		</div>
		<yuno-forms-divider></yuno-forms-divider>

		<div formGroupName="view" class="grid gap-2 gap-y-8">
			<div formArrayName="bounds" class="order-first grid gap-2">
				<yuno-forms-title>Extend</yuno-forms-title>
				<div [formArrayName]="1" class="grid grid-cols-3 items-center justify-center">
					<yuno-forms-span class="col-span-3 col-start-3 mr-2 text-right font-semibold">
						top right
					</yuno-forms-span>
					<div class="col-span-3 col-start-1 ml-8 gap-2">
						<div class="mb-1 flex items-center gap-2">
							<span>x:</span>
							<yuno-forms-number
								class="w-full"
								[formControlName]="0"
								[step]="0.01"></yuno-forms-number>
						</div>
						<div class="mb-1 flex items-center gap-2">
							<span>y:</span>
							<yuno-forms-number
								class="w-full"
								[formControlName]="1"
								[step]="0.01"></yuno-forms-number>
						</div>
					</div>
				</div>
				<div [formArrayName]="0" class="mb-2 grid grid-cols-3 items-center justify-center">
					<yuno-forms-span class="col-span-3 font-semibold">bottom left</yuno-forms-span>
					<div class="col-span-3 mr-8 gap-2">
						<div class="mb-1 flex items-center gap-2">
							<span>x:</span>
							<yuno-forms-number
								class="w-full"
								[formControlName]="0"
								[step]="0.01"></yuno-forms-number>
						</div>
						<div class="mb-1 flex items-center gap-2">
							<span>y:</span>
							<yuno-forms-number
								class="w-full"
								[formControlName]="1"
								[step]="0.01"></yuno-forms-number>
						</div>
					</div>
				</div>
				<div class="grid grid-cols-3 items-center justify-center gap-x-4">
					<yuno-forms-span class="col-span-2 font-semibold"
						>Select from fence:</yuno-forms-span
					>
					<yuno-forms-span class="font-semibold">Export to fence:</yuno-forms-span>

					<label class="yuno-form col-span-2">
						<select class="truncate" (change)="onSelectedFence($event, 'bounds')">
							<option [value]="'' || undefined || null" hidden>
								copy extend from fence
							</option>
							@for (select of service.fencesValues; track select; let i = $index) {
								<option [value]="select">
									{{ service.fencesDisplay[i] }}
								</option>
							}
						</select>
					</label>
					<button yuno-admin-button (click)="exportToFence('bounds')">Export...</button>
				</div>
			</div>
			<div formArrayName="maxBounds" class="grid gap-2">
				<yuno-forms-title>Max Extend</yuno-forms-title>
				<div class="flex flex-row items-start justify-between">
					<label class="yuno-form toggle flex-row-reverse gap-2">
						<yuno-forms-span class="font-semibold">
							Activate Max Bounds.
						</yuno-forms-span>
						<input
							class="toggle block"
							type="checkbox"
							[checked]="service.maxBounds.length"
							(change)="toggleMaxBounds($event)"
							name="maxboundsEnable" />
					</label>
					@if (service.maxBounds.length) {
						<button yuno-admin-button color="secondary" (click)="clearMaxBounds()">
							Clear
						</button>
					}
				</div>
				@if (service.maxBounds.length) {
					<div [formArrayName]="1" class="grid grid-cols-3 items-center justify-center">
						<yuno-forms-span
							class="col-span-3 col-start-3 mr-2 text-right font-semibold">
							top right
						</yuno-forms-span>
						<div class="col-span-3 col-start-1 ml-8 gap-2">
							<div class="mb-1 flex items-center gap-2">
								<span>x:</span>
								<yuno-forms-number
									class="w-full"
									[formControlName]="0"
									[step]="0.01"></yuno-forms-number>
							</div>
							<div class="mb-1 flex items-center gap-2">
								<span>y:</span>
								<yuno-forms-number
									class="w-full"
									[formControlName]="1"
									[step]="0.01"></yuno-forms-number>
							</div>
						</div>
					</div>
					<div
						[formArrayName]="0"
						class="mb-2 grid grid-cols-3 items-center justify-center">
						<yuno-forms-span class="col-span-3 font-semibold"
							>bottom left</yuno-forms-span
						>
						<div class="col-span-3 mr-8 gap-2">
							<div class="mb-1 flex items-center gap-2">
								<span>x:</span>
								<yuno-forms-number
									class="w-full"
									[formControlName]="0"
									[step]="0.01"></yuno-forms-number>
							</div>
							<div class="mb-1 flex items-center gap-2">
								<span>y:</span>
								<yuno-forms-number
									class="w-full"
									[formControlName]="1"
									[step]="0.01"></yuno-forms-number>
							</div>
						</div>
					</div>
					<div class="grid grid-cols-3 items-center justify-center gap-x-4">
						<yuno-forms-span class="col-span-2 font-semibold"
							>Select from fence:</yuno-forms-span
						>
						<yuno-forms-span class="font-semibold">Export to fence:</yuno-forms-span>

						<label class="yuno-form col-span-2">
							<select
								class="truncate"
								(change)="onSelectedFence($event, 'maxBounds')">
								<option [value]="'' || undefined || null" hidden>
									copy extend from fence
								</option>
								@for (
									select of service.fencesValues;
									track select;
									let i = $index
								) {
									<option [value]="select">
										{{ service.fencesDisplay[i] }}
									</option>
								}
							</select>
						</label>
						<button yuno-admin-button (click)="exportToFence('maxBounds')">
							Export...
						</button>
					</div>
				}
			</div>
			<yuno-forms-divider></yuno-forms-divider>

			<div class="grid grid-cols-3 gap-2">
				<div class="col-span-3 flex flex-row justify-between gap-2">
					<yuno-forms-title>View Options</yuno-forms-title>
					<a
						href="https://maplibre.org/maplibre-gl-js-docs/api/map/"
						rel="noopener"
						target="_blank"
						class="text-sm underline">
						Maplibre Documentation
					</a>
				</div>
				<yuno-forms-number
					class="col-span-1"
					[min]="0"
					[max]="22"
					formControlName="zoom"
					label="zoom"></yuno-forms-number>
				<yuno-forms-number
					class="col-span-1"
					[min]="0"
					[max]="22"
					formControlName="minZoom"
					label="minZoom"></yuno-forms-number>
				<yuno-forms-number
					class="col-span-1"
					[min]="0"
					[max]="22"
					formControlName="maxZoom"
					label="maxZoom"></yuno-forms-number>
				<yuno-forms-number
					class="col-span-1"
					[min]="0"
					[max]="60"
					formControlName="pitch"
					label="pitch"></yuno-forms-number>
				<yuno-forms-number
					class="col-span-1"
					[min]="0"
					[max]="60"
					formControlName="minPitch"
					label="minPitch"></yuno-forms-number>
				<yuno-forms-number
					class="col-span-1"
					formControlName="maxPitch"
					[min]="0"
					[max]="60"
					label="maxPitch"></yuno-forms-number>
				<yuno-forms-number
					class="col-span-1"
					formControlName="bearing"
					[min]="0"
					[max]="360"
					label="bearing"></yuno-forms-number>
			</div>
			<div class="grid grid-cols-2 gap-x-4">
				<span class="col-span-2 text-sm font-semibold">Zoom Options</span>
				<yuno-forms-toggle
					labelPos="side"
					formControlName="boxZoom"
					label="Box Zoom"></yuno-forms-toggle>
				<yuno-forms-toggle
					labelPos="side"
					formControlName="doubleClickZoom"
					label="Double Click Zoom"></yuno-forms-toggle>
			</div>
			<div class="grid grid-cols-2 gap-x-4">
				<span class="col-span-2 text-sm font-semibold">Interaction Options</span>
				<yuno-forms-toggle
					labelPos="side"
					class="col-span-2"
					formControlName="interactive"
					label="Interactive"></yuno-forms-toggle>
				<yuno-forms-toggle
					labelPos="side"
					formControlName="dragPan"
					label="DragPan"></yuno-forms-toggle>
				<yuno-forms-toggle
					labelPos="side"
					formControlName="dragRotate"
					label="Rotate & Pitch"></yuno-forms-toggle>
			</div>
			<div class="grid grid-cols-2 gap-x-4">
				<span class="col-span-3 text-sm font-semibold">Input Options</span>
				<yuno-forms-toggle
					labelPos="side"
					formControlName="keyboard"
					label="Keyboard"></yuno-forms-toggle>
				<yuno-forms-toggle
					labelPos="side"
					formControlName="scrollZoom"
					label="ScrollZoom"></yuno-forms-toggle>
			</div>
			<div class="grid grid-cols-3 gap-x-4">
				<span class="col-span-3 text-sm font-semibold">Touch Options</span>
				<yuno-forms-toggle
					labelPos="side"
					formControlName="touchZoom"
					label="Zoom"></yuno-forms-toggle>
				<yuno-forms-toggle
					labelPos="side"
					formControlName="touchPitch"
					label="Pitch"></yuno-forms-toggle>
				<yuno-forms-toggle
					labelPos="side"
					formControlName="touchRotate"
					label="Rotate"></yuno-forms-toggle>
				<yuno-admin-annotation class="col-span-3 mt-4" type="info">
					When Touch Options > Zoom is disabled, both Pitch and Rotate won't work either
				</yuno-admin-annotation>
			</div>
			<yuno-forms-divider></yuno-forms-divider>

			<div class="grid grid-cols-1 gap-2">
				<yuno-forms-title>Other Options</yuno-forms-title>
				<div class="grid grid-cols-1" formGroupName="clustering">
					<yuno-forms-span class="font-semibold">Panorama Clustering</yuno-forms-span>
					<section formGroupName="mp" class="flex flex-col gap-4">
						<yuno-forms-toggle
							labelPos="side"
							formControlName="active"
							label="Active"></yuno-forms-toggle>
						<yuno-forms-number
							labelPos="side"
							formControlName="minZoom"
							[step]="0.5"
							[min]="1"
							[max]="24"
							label="Panorama Cluster MinZoom"></yuno-forms-number>
					</section>
				</div>
			</div>
		</div>
	</form>
}
