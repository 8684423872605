import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Map } from 'maplibre-gl';

import { Legend, LegendOptions } from '@yuno/api/interface';

import { LegendaItemContentComponent } from '../legenda-item-content/legenda-item-content.component';


@Component({
	selector: 'yuno-legend-item',
	standalone: true,
	imports: [LegendaItemContentComponent],
	templateUrl: './legend-item.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendItemComponent implements OnInit {
	@Input() map: Map;
	@Input() legend: Partial<Legend>;
	@Input() language: string | undefined = 'nl';
	@Input() data: LegendOptions;
	list = false;

	ngOnInit(): void {
		if (!this.data || !this.data.list) {
			return;
		}

		this.list = this.data.list?.length >= 1;
	}
}
