import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { SafeUser, User, UserRoles } from '@yuno/api/interface';

import { usersActions } from './users.actions';
import { usersFeature } from './users.state';


@Injectable()
export class UsersFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(usersFeature.selectLoaded));
	users$ = this.store.pipe(select(usersFeature.selectUsers));
	status$ = this.store.pipe(select(usersFeature.selectStatus));
	getUserApps$ = this.store.pipe(select(usersFeature.selectSelectedUserApps));

	getUsers(): void {
		this.store.dispatch(usersActions.load());
	}

	updateRole(role: UserRoles, index: number): void {
		this.store.dispatch(usersActions.updateRole({ role, index }));
	}

	resetPassword(email: string): void {
		this.store.dispatch(usersActions.resetPassword({ email }));
	}

	resendInvite(email: string): void {
		this.store.dispatch(usersActions.resendInvite({ email }));
	}

	userData(email: string): void {
		this.store.dispatch(usersActions.userData({ email }));
	}

	removeUserData(): void {
		this.store.dispatch(usersActions.clearData());
	}

	addUser(user: Partial<User>): void {
		this.store.dispatch(usersActions.addUser({ user }));
	}

	deleteUser(user: Partial<SafeUser>): void {
		this.store.dispatch(usersActions.deleteUser({ user }));
	}
}
