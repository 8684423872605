import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { ArrayValidators, CustomValidators } from '@yuno/angular/forms';
import { CreateAppDto } from '@yuno/api/dto';

import { CreateAppFacade } from '../../data-access';

export interface CreateAppForm {
	id: FormControl<string>;
	url: FormArray<FormControl<string>>;
	client: FormControl<string>;
	language: FormControl<string>;
}

@Injectable({
	providedIn: 'root'
})
export class CreateAppService {
	private readonly facade = inject(CreateAppFacade);

	form: FormGroup<CreateAppForm>;

	clientsSelect: string[] = [];
	clientsDisplay: string[] = [];

	get urls(): FormArray<FormControl<string>> {
		return this.form.get('url') as FormArray<FormControl<string>>;
	}

	get urlValues(): string[] {
		return this.form.get('url')?.value || [];
	}

	createFormGroup(): void {
		this.form = new FormGroup<CreateAppForm>({
			id: new FormControl<string>('', {
				nonNullable: true,
				validators: [Validators.required, Validators.minLength(4)]
			}),
			url: new FormArray<FormControl<string>>([], ArrayValidators.minLength(1)),
			client: new FormControl<string>('', {
				nonNullable: true,
				validators: Validators.required
			}),
			language: new FormControl<string>('nl', { nonNullable: true })
		});

		this.addUrl();
	}

	addUrl(): void {
		const control = new FormControl<string>('', {
			nonNullable: true,
			validators: [
				Validators.required,
				Validators.minLength(3),
				CustomValidators.noSpacesLowerCase()
			]
		});
		this.urls.push(control);
	}

	removeUrl(index: number): void {
		this.urls.removeAt(index);
	}

	onSubmit(): void {
		this.form.markAllAsTouched();

		if (this.form.valid) {
			const dto: CreateAppDto = this.form.value as CreateAppDto;
			this.facade.createApp(dto);
		}
	}
}
