<yuno-admin-navbar-buttons-container>
	<button yuno-admin-button color="success" (click)="create()">Save</button>
</yuno-admin-navbar-buttons-container>
<div class="flex flex-col gap-8">
	<yuno-card>
		@if (data$ | async) {
			<ng-container content>
				<yuno-forms-title>Create new Application</yuno-forms-title>
				@if (service.form; as form) {
					<form [formGroup]="form" class="grid grid-cols-1 gap-4">
						<div class="grid grid-cols-4 gap-2">
							<yuno-forms-text
								class="col-span-3"
								formControlName="id"
								placeholder="Application Title"
								label="Application Title">
								@if (service.form.get('id')?.errors?.['required']) {
									<span> give the application a title! </span>
								}
								@if (service.form.get('id')?.errors?.['minlength']) {
									<span>
										the application should be atleast:
										{{
											service.form.get('id')?.errors?.['minlength']
												.requiredLength
										}}
										characters long!
									</span>
								}
							</yuno-forms-text>
							<yuno-forms-select
								class="col-span-1"
								formControlName="language"
								placeholder="Choose Language"
								[selectValues]="['nl', 'de', 'en', 'fr', 'es', 'it', 'da']"
								[display]="[
									'Nederlands',
									'Duits',
									'Engels',
									'Frans',
									'Spaans',
									'Italiaans',
									'Deens'
								]"
								label="Language"></yuno-forms-select>
						</div>
						<div class="grid grid-cols-1">
							<yuno-forms-title class="mt-6">Urls</yuno-forms-title>
							<yuno-forms-span
								>the first url will also be the foldername
							</yuno-forms-span>
						</div>
						<yuno-forms-array-container
							[control]="service.urls"
							formArrayName="url"
							cdkDropList
							(cdkDropListDropped)="drop($event)">
							<div arrayBody class="grid grid-cols-1 gap-2">
								@for (url of service.urls.controls; track url; let i = $index) {
									<yuno-admin-drag-row
										[keepButtons]="true"
										cdkDrag
										cdkDragLockAxis="y"
										(mouseenter)="hover = i"
										(mouseleave)="hover = null">
										<div class="flex-1 select-none" title>
											<yuno-forms-text
												[formControlName]="i"
												placeholder="Application Url">
												@if (
													service.urls.controls[i].errors?.[
														'minlength'
													] ||
													service.urls.controls[i].errors?.['required']
												) {
													<span>
														please provide at least 3 characters
													</span>
												}

												@if (
													service.urls.controls[i].errors?.[
														'noSpacesLowerCase'
													]?.['whitespace']
												) {
													<span> whitespaces are not allowed! </span>
												}

												@if (
													service.urls.controls[i].errors?.[
														'noSpacesLowerCase'
													]?.['uppercase']
												) {
													<span> only lowercase is allowed </span>
												}
											</yuno-forms-text>
										</div>
										<ng-container buttons>
											<button
												yuno-admin-button
												(click)="onRemove(i)"
												color="secondary">
												delete
											</button>
										</ng-container>
									</yuno-admin-drag-row>
								}
							</div>
							<div arrayErrors>
								@if (!service.urls.valid && !service.urls.errors) {
									<span> one of the urls is not valid! </span>
								}
								@if (service.urls.errors?.['minlength']) {
									<span> please provide at least one url! </span>
								}
							</div>
						</yuno-forms-array-container>
						<div class="flex items-center gap-4">
							<button
								yuno-admin-button
								color="secondary"
								[add]="true"
								(click)="onAdd()">
								Add Url
							</button>
						</div>
						<section class="my-8 flex flex-col gap-2">
							<yuno-forms-title>Select a Client</yuno-forms-title>
							<yuno-forms-select
								formControlName="client"
								placeholder="Choose Client..."
								[selectValues]="service.clientsSelect"
								[display]="service.clientsDisplay"
								label="Client">
								@if (service.form.get('client')?.errors?.['required']) {
									<span> please select a client! </span>
								}
							</yuno-forms-select>
						</section>
					</form>
				}
			</ng-container>
		}
	</yuno-card>
</div>
