import { Injectable, Injector, inject } from "@angular/core";
import { NgElement, WithProperties, createCustomElement } from "@angular/elements";



import "../components/text-iframe/text-iframe.component";
import { TextTogglesToggleComponent } from "../components/text-toggles/toggle/toggle.component";
import { TextBlockComponent, TextButtonComponent, TextCollapsibleComponent, TextEmbedComponent, TextIframeComponent, TextImageComponent, TextLinkComponent, TextListComponent, TextListDropdownComponent, TextListItemComponent, TextTogglesComponent, TextVideoComponent } from "./../components";


/**
 * WHEN ADDING A NEW ELEMENT ALSO INCLUDE IT INSIDE THE SAFE-HTML PIPE
 * ./libs/angular/pipes/src/lib/safe-html/helpers.textfield.ts
 */
const YunoCustomElements = [
	{
		component: TextBlockComponent,
		element: 'yuno-text-block'
	},
	{
		component: TextButtonComponent,
		element: 'yuno-text-button'
	},
	{
		component: TextCollapsibleComponent,
		element: 'yuno-text-collapsible'
	},
	{
		component: TextEmbedComponent,
		element: 'yuno-text-embed'
	},
	{
		component: TextImageComponent,
		element: 'yuno-text-image'
	},
	{
		component: TextLinkComponent,
		element: 'yuno-text-link'
	},
	{
		component: TextListComponent,
		element: 'yuno-text-list'
	},
	{
		component: TextListItemComponent,
		element: 'yuno-text-list-item'
	},
	{
		component: TextListDropdownComponent,
		element: 'yuno-text-list-dropdown'
	},
	{
		component: TextTogglesComponent,
		element: 'yuno-text-toggles'
	},
	{
		component: TextTogglesToggleComponent,
		element: 'yuno-text-toggles-toggle'
	},
	{
		component: TextVideoComponent,
		element: 'yuno-text-video'
	},
	{
		component: TextIframeComponent,
		element: 'yuno-text-iframe'
	}
];

declare global {
	interface HTMLElementTagNameMap {
		'yuno-text-toggles-toggle': NgElement & WithProperties<{ toggle: string }>;
	}
}

@Injectable({
	providedIn: 'root'
})
export class TextfieldLoadCustomElementsService {
	private injector = inject(Injector);
	hasLoaded = false;

	/**
	 * this loads all Custom Elements needed in TextFields
	 * it enables reading Angular Elements directly from a HTML string/file
	 */
	init(): void {
		// make sure not to run twice
		if (this.hasLoaded) {
			return;
		}

		this.hasLoaded = true;
		// Register all internal Textfield Components as CustomElements
		for (const elem of YunoCustomElements) {
			// Convert `Component` to a custom element.
			const customElement = createCustomElement(elem.component, { injector: this.injector });
			// Register the custom element with the browser.
			customElements.define(elem.element, customElement);
		}
	}
}
