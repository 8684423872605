import { Injectable, TrackByFunction, signal } from '@angular/core';

import { TextfieldDataInject } from '@yuno/angular/textfield';
import { PageItemImgBtn, textfieldComponetsHasId } from '@yuno/api/interface';


@Injectable({
	providedIn: 'root'
})
export class TextfieldDataService {
	$language = signal<string>('nl');

	setLanguage(lang: string): void {
		this.$language.set(lang);
	}

	getComponentId: TrackByFunction<TextfieldDataInject> = (index, component) => {
		if (textfieldComponetsHasId(component.data)) {
			return component.data._id;
		}

		if (typeof component.data === 'string') {
			return component.data;
		}

		if (component.constructor.name === 'PageItemImgBtn') {
			return (component.data as PageItemImgBtn).item?._id;
		}

		return index;
	};

	getUpdatedBy(item: TextfieldDataInject) {
		const defaultReturn = 'XKP visual engineers';
		if (typeof item.data === 'string') {
			return defaultReturn;
		}

		const hasUpdatedBy = 'updatedBy' in item.data;

		if (hasUpdatedBy) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (item.data as any).updatedBy;
		}
		return defaultReturn;
	}

	getUpdatedAt(item: TextfieldDataInject) {
		const defaultReturn = new Date('1999-12-31');
		if (typeof item.data === 'string' || typeof item.data === 'number') {
			return defaultReturn;
		}

		const hasUpdatedAt = 'updatedAt' in item.data;

		if (hasUpdatedAt) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (item.data as any).updatedAt;
		}
		return defaultReturn;
	}
}
