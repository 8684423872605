import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AsyncPipe } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	forwardRef,
	inject
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, startWith, tap } from 'rxjs';

import { ChooseTemplateComponent } from '@yuno/admin/features/templates';
import {
	TextfieldEditorService,
	TextfieldLibraryContainerComponent,
	TextfieldLibraryService
} from '@yuno/admin/features/textfield-pages';
import { YunoAdminButtonTableComponent } from '@yuno/admin/ui';
import { YunoSharedUiButtonComponent, YunoSharedUiButtonTableComponent } from '@yuno/shared/ui';

import { YunoFormsLabelRequired } from '../../pipes/label-required/label-required.pipe';
import {
	YunoFormsDefaultComponent,
	YunoFormsDefaultTemplateComponent
} from '../_default/default.component';
import { YunoFormsValidatorMessagesComponent } from '../_validator-messages/validator-messages.component';

@Component({
	selector: 'yuno-forms-textfield',
	template: `
		@if (data$ | async; as data) {
			<label class="yuno-form">
				@if (label) {
					<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
				}
				@if (!ngControl.control?.value || !displayName) {
					<input
						class="browse truncate"
						type="text"
						[id]="ngControl.name"
						[name]="ngControl.name"
						[placeholder]="placeholder"
						[formControl]="getFormControl()"
						(change)="onChanged($event)" />
				}
				@if (ngControl.control?.value && displayName) {
					<input
						class="browse truncate"
						type="text"
						disabled
						[id]="ngControl.name + '_value'"
						[name]="ngControl.name + '_value'"
						[value]="displayName" />
				}
				<div class="absolute right-2 top-[27px] mb-2">
					@if (!ngControl.control?.value) {
						<button
							yuno-shared-ui-button-table
							color="primary"
							(click)="browseLibrary()">
							Add
						</button>
					}
					@if (ngControl.control?.value) {
						<div class="flex gap-2">
							<button
								yuno-shared-ui-button-table
								color="primary"
								(click)="editTextfield()">
								Edit
							</button>
							<button
								yuno-shared-ui-button-table
								color="secondary"
								(click)="clearTextfield()">
								Clear
							</button>
						</div>
					}
				</div>
				<yuno-validator-messages [control]="ngControl.control">
					<ng-content></ng-content>
				</yuno-validator-messages>
			</label>
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		YunoSharedUiButtonComponent,
		YunoSharedUiButtonTableComponent,
		YunoAdminButtonTableComponent,
		FormsModule,
		ReactiveFormsModule,
		YunoFormsValidatorMessagesComponent,
		AsyncPipe,
		YunoFormsLabelRequired,
		ChooseTemplateComponent
	]
})
export class YunoFormsTextfieldComponent
	extends YunoFormsDefaultComponent
	implements AfterViewInit
{
	@Input() displays: string[];
	@Input() set values(values: string[]) {
		this._values = values;
		const id = this.ngControl.control?.value;
		if (id) {
			this.setDisplayName(id);
		}
	}
	private _values: string[];

	@Output() changes = new EventEmitter<string>();

	private readonly library = inject(TextfieldLibraryService);
	private readonly editor = inject(TextfieldEditorService);

	private overlay = inject(Overlay);
	private overlayRef?: OverlayRef;
	private browse = false;

	displayName: string;

	data$ = combineLatest({
		selectTextfield: this.library.selected$.pipe(
			startWith(undefined),
			tap(text => {
				if (this.browse) {
					this.ngControl.control?.setValue(text?._id);
				}
			})
		),
		editTextfield: this.library.editor$.pipe(
			startWith(undefined),
			tap(id => {
				if (id && this.ngControl.name) {
					this.library.key$.next(this.ngControl.name as string);
					this.closeOverlay();
				}
			})
		),
		close: this.library.close$.pipe(
			startWith(undefined),
			tap(() => {
				if (this.browse) {
					this.closeOverlay();
				}
			})
		),
		saved: this.editor.closed$.pipe(
			startWith(undefined),
			tap(id => {
				if (id) {
					this.ngControl.control?.setValue(id);
				}
			})
		)
	});

	ngAfterViewInit() {
		this.ngControl?.control?.valueChanges.subscribe(value => {
			if (value) {
				this.setDisplayName(value);
			}
			this.cdr.detectChanges();
		});
	}

	onChanged(event: Event) {
		// Get the value of the selected option
		const selectedValue = (event.target as HTMLInputElement).value;

		// Outputs the changes
		this.changes.emit(selectedValue);
	}

	setDisplayName(id: string) {
		if (this._values && this._values.length >= 1) {
			const index = this._values.indexOf(id);
			const textfield = {
				_id: this._values[index] as string,
				id: (this.displays?.[index] || '') as string
			};
			// this.library.selectTextfield(textfield as Textfield);
			this.displayName = textfield.id;
		}
	}

	browseLibrary() {
		this.overlayRef = this.overlay.create({
			positionStrategy: this.overlay.position().global(),
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			hasBackdrop: false,
			panelClass: 'z-100'
		});

		const imageEditor = new ComponentPortal(TextfieldLibraryContainerComponent);
		const t = this.overlayRef?.attach(imageEditor);
		t?.changeDetectorRef?.detectChanges();
		this.browse = true;
	}

	editTextfield() {
		const id = this.ngControl.control?.value;
		if (id) {
			this.library.editTextfield(id);
		}
	}

	clearTextfield() {
		this.ngControl.control?.reset();
		this.library.selected$.next(undefined);
	}

	closeOverlay(): void {
		this.overlayRef?.dispose();
		this.overlayRef = undefined;
		this.browse = false;
	}
}

@Component({
	selector: 'yuno-forms-template-textfield',
	template: `
		@if (data$ | async; as data) {
			<label class="yuno-form">
				@if (label) {
					<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
				}
				<input
					class="browse truncate"
					type="text"
					[ngModel]="value"
					(ngModelChange)="valueChange($event)"
					[placeholder]="placeholder"
					[required]="required" />
				<div class="absolute right-2 top-[27px] mb-2">
					@if (!data?.selectTextfield?._id) {
						<button
							yuno-shared-ui-button-table
							color="primary"
							(click)="browseLibrary()">
							Add
						</button>
					}
					@if (data?.selectTextfield?._id) {
						<div class="flex gap-2">
							<button
								yuno-shared-ui-button-table
								color="primary"
								(click)="editTextfield()">
								Edit
							</button>
							<button
								yuno-shared-ui-button-table
								color="secondary"
								(click)="clearTextfield()">
								Clear
							</button>
						</div>
					}
				</div>
			</label>
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		YunoSharedUiButtonComponent,
		YunoSharedUiButtonTableComponent,
		FormsModule,
		AsyncPipe,
		YunoFormsLabelRequired
	],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => YunoFormsTextfieldTemplateComponent),
			multi: true
		}
	]
})
export class YunoFormsTextfieldTemplateComponent extends YunoFormsDefaultTemplateComponent {
	@Output() changes = new EventEmitter<string>();

	private readonly library = inject(TextfieldLibraryService);
	private readonly editor = inject(TextfieldEditorService);

	private overlay = inject(Overlay);
	private overlayRef?: OverlayRef;
	private browse = false;

	data$ = combineLatest({
		selectTextfield: this.library.selected$.pipe(
			startWith(undefined),
			tap(text => {
				if (this.browse && text?._id) {
					this.valueChange(text._id);
				}
			})
		),
		editTextfield: this.library.editor$.pipe(
			startWith(undefined),
			tap(id => {
				if (id && this.value) {
					this.library.key$.next(this.value as string);
					this.closeOverlay();
				}
			})
		),
		close: this.library.close$.pipe(
			startWith(undefined),
			tap(() => {
				if (this.browse) {
					this.closeOverlay();
				}
			})
		),
		saved: this.editor.closed$.pipe(
			startWith(undefined),
			tap(id => {
				if (id) {
					this.valueChange(id);
				}
			})
		)
	});

	browseLibrary(): void {
		this.overlayRef = this.overlay.create({
			positionStrategy: this.overlay.position().global(),
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			hasBackdrop: false,
			panelClass: 'z-100'
		});

		const imageEditor = new ComponentPortal(TextfieldLibraryContainerComponent);
		const t = this.overlayRef?.attach(imageEditor);
		t?.changeDetectorRef?.detectChanges();
		this.browse = true;
	}

	editTextfield() {
		const id = this.value;
		if (id && typeof id === 'string') {
			this.library.editTextfield(id);
		}
	}

	clearTextfield() {
		this.valueChange('');
		this.library.selected$.next(undefined);
	}

	closeOverlay(): void {
		this.overlayRef?.dispose();
		this.overlayRef = undefined;
		this.browse = false;
	}
}
