import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { combineLatest, map } from 'rxjs';

import { YunoAdminButtonsModule, YunoAdminTableComponent, YunoCardModule } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { Dataset } from '@yuno/api/interface';

import { DatasetsFacade } from '../../data-access';

type DatasetTable = {
	_id: string;
	id: string;
	fences: number;
	layers: number;
	markers: number;
	panoramas: number;
	objects: number;
	categories: string;
	states: number;
};

@Component({
	standalone: true,
	imports: [
		AsyncPipe,
		YunoCardModule,
		YunoAdminTableComponent,
		RouterModule,
		YunoAdminButtonsModule
	],
	selector: 'yuno-admin-datasets',
	templateUrl: './datasets.component.html',
	styleUrls: ['./datasets.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetsComponent extends AppDataComponent implements OnInit {
	private datasetFacade = inject(DatasetsFacade);

	data$ = combineLatest({
		datasets: this.datasetFacade.datasets$.pipe(
			map(data => {
				const datasets: DatasetTable[] = data.map(ds => ({
					_id: ds?._id || '',
					id: ds?.id || '',
					fences: ds.data?.fences?.length || 0,
					layers: ds.data?.layers?.length || 0,
					markers: ds.data?.markers?.length || 0,
					panoramas: ds.data?.panoramas?.length || 0,
					objects: ds.data?.objects?.length || 0,
					categories: ` ${ds.data?.markerCategories?.length || 0} /
					  ${ds.data?.photoCategories?.length || 0} / ${ds.data?.participates?.length || 0} `,
					states: ds.states?.length || 0
				}));
				return datasets as { [key: string]: unknown }[];
			})
		),
		selected: this.datasetFacade.selectedDataset$
	});

	ngOnInit(): void {
		this.datasetFacade.get();
	}

	onCreate() {
		this.datasetFacade.clearSelect();
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
	}

	onSelect(row: Partial<Dataset>): void {
		this.router.navigate(['edit', row._id], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<Dataset>): void {
		row._id && this.datasetFacade.duplicate(row._id);
	}

	onDelete(row: Partial<Dataset>): void {
		row._id && this.datasetFacade.delete(row._id);
	}
}
