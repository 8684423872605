import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
	standalone: true,
	selector: 'yuno-admin-side-options-button',
	template: ` <div
		class="mb-1 h-8 w-full flex-1 truncate rounded-[1.25rem] px-5 leading-8 transition-colors duration-500 ease-in-out hover:cursor-pointer hover:bg-gray-100"
		[ngClass]="{
			'!pointer-events-none bg-yuno-portal-blue !text-white': active
		}">
		<ng-content></ng-content>
	</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass, AsyncPipe, NgTemplateOutlet, RouterLink, RouterLinkActive],
	styleUrls: ['./side-options-button.component.scss']
})
export class SideOptionsButtonComponent {
	@Input() active = false;
}
