@if (textfields$ | async; as text) {
	<yuno-admin-navbar-buttons-container>
		<button yuno-admin-save-button (click)="onSave()"></button>
	</yuno-admin-navbar-buttons-container>
	<div class="flex flex-col gap-8">
		<yuno-card>
			<ng-container title>Project</ng-container>
			<div content class="grid grid-cols-1 gap-4">
				@if (!minimalAppRole(userRoles.EDITOR)) {
					<yuno-user-rights-message></yuno-user-rights-message>
				}
				<form [formGroup]="service.appForm" class="flex flex-col gap-6">
					<yuno-forms-toggle formControlName="public" label="Public"></yuno-forms-toggle>
					<div class="flex flex-col gap-2">
						<yuno-forms-span class="font-semibold">Url *</yuno-forms-span>
						<yuno-forms-array-container [control]="service.urls" formArrayName="url">
							<div
								arrayBody
								class="grid grid-cols-1 gap-1 rounded-lg bg-gray-100 p-2">
								@for (url of service.urls.controls; track url; let i = $index) {
									<yuno-admin-drag-row
										[hideDrag]="true"
										[keepButtons]="true"
										(mouseenter)="hover = i"
										(mouseleave)="hover = null">
										<div class="flex-1 select-none" title>
											<yuno-forms-text
												[formControlName]="i"
												placeholder="Application Url">
												@if (
													service.urls.controls[i].errors?.[
														'minlength'
													] ||
													service.urls.controls[i].errors?.['required']
												) {
													<span>
														please provide at least 3 characters
													</span>
												}
												@if (
													service.urls.controls[i].errors?.[
														'noSpacesLowerCase'
													]?.['whitespace']
												) {
													<span> whitespaces are not allowed! </span>
												}
												@if (
													service.urls.controls[i].errors?.[
														'noSpacesLowerCase'
													]?.['uppercase']
												) {
													<span> only lowercase is allowed </span>
												}
											</yuno-forms-text>
										</div>
										<ng-container buttons>
											<button
												yuno-admin-delete-admin-button
												(click)="onRemove(i)"></button>
										</ng-container>
									</yuno-admin-drag-row>
								}
							</div>
							<div arrayErrors>
								@if (!service.urls.valid && !service.urls.errors) {
									<span> one of the urls is not valid! </span>
								}
								@if (service.urls.errors?.['minlength']) {
									<span> please provide at least one url! </span>
								}
							</div>
						</yuno-forms-array-container>
						<button yuno-admin-add-button class="self-end" (click)="onAdd()">
							Add Url
						</button>
					</div>
				</form>
				<form [formGroup]="service.configForm" class="flex flex-col gap-6">
					<!--	<yuno-forms-select-->
					<!--		formControlName="language"-->
					<!--		label="Application language"-->
					<!--		[display]="service.languageDisplay"-->
					<!--		[selectValues]="service.languageSelect"></yuno-forms-select>-->
					<yuno-forms-text formControlName="title" label="Application title" />
					<div class="col-span-2 flex flex-col gap-1">
						<section formGroupName="customTitle">
							@for (lang of service.languageSelect; track lang) {
								@if (lang === this.language) {
									<yuno-forms-text
										[formControlName]="lang"
										label="Custom Browser Title" />
								}
							}
						</section>
						<yuno-admin-annotation type="info">
							This will replace the browser title: "Project Atlas |
							{{ service.title.value }}" in the application.
						</yuno-admin-annotation>
					</div>
					<div class="col-span-2 flex flex-col gap-1">
						<section class="grid grid-cols-2 gap-4">
							<yuno-forms-select
								formControlName="fontHeader"
								label="Font Header"
								placeholder="default"
								[display]="service.fontDisplay"
								[selectValues]="service.fontSelect"></yuno-forms-select>
							<yuno-forms-select
								formControlName="fontParagraph"
								label="Font Paragraph"
								placeholder="default"
								[display]="service.fontDisplay"
								[selectValues]="service.fontSelect"></yuno-forms-select>
						</section>
						<yuno-admin-annotation type="info">
							This will replace the font in the application.
						</yuno-admin-annotation>
					</div>
				</form>
			</div>
		</yuno-card>
		<!-- LANGUAGE -->

		<yuno-card>
			<ng-container title>Languages</ng-container>
			<form [formGroup]="service.configForm" content class="grid grid-cols-1 gap-4">
				<ng-container
					>Set the language for Project Atlas. You can add additional languages using the
					button below. The language at the top of the list will be the default language.
					When you add more than one language, a pop-up will appear add the top of every
					screen in this portal that contains text fields requiring input for the added
					languages.
				</ng-container>
				<yuno-forms-array-container
					[control]="service.languages"
					formArrayName="languages"
					cdkDropList
					(cdkDropListDropped)="drop($event)">
					<div arrayBody class="grid grid-cols-1 gap-2">
						@for (item of service.languages.value; track item; let i = $index) {
							<yuno-admin-drag-row cdkDrag cdkDragLockAxis="y">
								<ng-container title>
									<div class="flex items-center justify-between">
										<yuno-forms-select
											[formControlName]="i"
											[placeholder]="'Choose a language    '"
											[display]="service.languageDisplay"
											[selectValues]="service.languageSelect">
										</yuno-forms-select>
										<button
											yuno-admin-delete-admin-button
											(click)="service.removeLanguage(i)"></button>
									</div>
								</ng-container>
							</yuno-admin-drag-row>
						}
					</div>
				</yuno-forms-array-container>
				<div class="flex items-center justify-end">
					<button yuno-admin-add-button (click)="service.addLanguage()">
						Add additional language
					</button>
				</div>
			</form>
		</yuno-card>
		<!-- HELP -->
		<yuno-card>
			<ng-container title>Help</ng-container>
			<ng-container content>
				<form [formGroup]="service.configForm" class="grid grid-cols-1 gap-4">
					<section formGroupName="help">
						<yuno-forms-toggle
							formControlName="active"
							label="Active"></yuno-forms-toggle>
					</section>
					<!-- LANGUAGE -->
					<section [formGroup]="service.helpDisplay">
						@for (lang of service.languageSelect; track lang) {
							@if (lang === this.language) {
								<yuno-forms-text [formControlName]="lang" label="Display" />
							}
						}
					</section>
					<section [formGroup]="service.helpTextfields" class="grid grid-cols-2 gap-4">
						<yuno-forms-select
							formControlName="desktop"
							label="Desktop"
							[selectValues]="service.defaultHelpTextSelect"
							[display]="service.defaultHelpTextDisplay"></yuno-forms-select>
						<yuno-forms-select
							formControlName="mobile"
							label="Mobile"
							[selectValues]="service.defaultHelpMobileSelect"
							[display]="service.defaultHelpMobileDisplay"></yuno-forms-select>
					</section>
					<section formGroupName="help" class="grid grid-cols-2 gap-4">
						<yuno-forms-select
							formControlName="target"
							label="Target (choose where the textfield is displayed)"
							[selectValues]="service.targetValues"
							[display]="service.targetValues"></yuno-forms-select>
					</section>
				</form>
			</ng-container>
		</yuno-card>
		<!-- INTERFACE / COLORS -->
		<yuno-card>
			<ng-container title>Interface / Colors</ng-container>
			<div content class="flex flex-col gap-8">
				<form [formGroup]="service.configForm" class="grid grid-cols-1 gap-6">
					<section
						formGroupName="interface"
						class="grid grid-cols-2 gap-6 md:grid-cols-3 xl:grid-cols-5">
						<yuno-forms-toggle
							formControlName="mapOnly"
							label="Map Only"></yuno-forms-toggle>
						<yuno-forms-toggle
							formControlName="controlZoom"
							label="Control Zoom"></yuno-forms-toggle>
						<yuno-forms-toggle
							formControlName="fullscreenButton"
							label="Fullscreen Button"></yuno-forms-toggle>
					</section>
					<section
						formGroupName="interface"
						class="grid grid-cols-2 gap-6 md:grid-cols-3 xl:grid-cols-5">
						<yuno-forms-toggle
							formControlName="fullscreenPopup"
							label="Fullscreen Popup"></yuno-forms-toggle>
						<yuno-forms-toggle
							formControlName="embedFullscreenPopup"
							label="Embed Fullscreen Popup"></yuno-forms-toggle>
					</section>
					<yuno-admin-annotation>
						<span>
							The
							<span class="font-semibold">"Fullscreen Popup"</span> is enabled when
							using inside a iFrame.
						</span>
						<span>
							The
							<span class="font-semibold">"Embed Fullscreen Popup"</span>
							is enabled when using the
							<span class="font-semibold italic">"&embed=true"</span> code in the
							application url.
						</span>
					</yuno-admin-annotation>
					<!-- BUTTON STYLE -->
					<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
						<yuno-forms-title class="col-span-full">Application Style</yuno-forms-title>
						<ng-container formGroupName="interface">
							<yuno-forms-select
								formControlName="buttonStyle"
								[display]="['Default', 'Rounded', 'Square']"
								[selectValues]="[
									'default',
									'rounded',
									'square'
								]"></yuno-forms-select>
						</ng-container>
						<div class="flex items-center justify-center gap-2">
							<yuno-button>Preview</yuno-button>
						</div>
					</div>
					<yuno-admin-annotation>
						<span>
							This will change the style of all components using a rounded finish, to
							either decrease or remove the border radius.
						</span>
						<span>
							This includes buttons, containers, dropdown items, hover states and
							more.
						</span>
					</yuno-admin-annotation>
					<!-- NAVBAR -->
					<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
						<yuno-forms-title class="col-span-full">Navbar</yuno-forms-title>
						<ng-container formGroupName="interface">
							<yuno-forms-select
								formControlName="navbarType"
								label="Navbar Type"
								[display]="service.navbarTypeValues"
								[selectValues]="service.navbarTypeValues"></yuno-forms-select>
						</ng-container>
						<span></span>
						<ng-container formGroupName="colors">
							<ng-container formGroupName="navbar">
								<yuno-forms-toggle
									formControlName="underline"
									label="Active Underline"></yuno-forms-toggle>
							</ng-container>
						</ng-container>
					</div>
					<div formGroupName="colors">
						<div
							formGroupName="navbar"
							class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
							<yuno-forms-color formControlName="background" label="Background" />
							<yuno-forms-color formControlName="font" label="Font" />
							<yuno-forms-color formControlName="active" label="Active Background" />
							<yuno-forms-color formControlName="activeFont" label="Active Font" />
						</div>
					</div>
					<!-- EMBED NAVBAR -->
					<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
						<yuno-forms-title class="col-span-full">Embedded Navbar</yuno-forms-title>
						<ng-container formGroupName="interface">
							<yuno-forms-select
								formControlName="embedType"
								label="Navbar Type"
								[display]="service.navbarTypeValues"
								[selectValues]="service.navbarTypeValues"></yuno-forms-select>
						</ng-container>
						<span></span>
						<ng-container formGroupName="colors">
							<ng-container formGroupName="embedNavbar">
								<yuno-forms-toggle
									formControlName="underline"
									label="Active Underline"></yuno-forms-toggle>
							</ng-container>
						</ng-container>
					</div>
					<div formGroupName="colors">
						<div
							formGroupName="embedNavbar"
							class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
							<yuno-forms-color formControlName="background" label="Background" />
							<yuno-forms-color formControlName="font" label="Font" />
							<yuno-forms-color formControlName="active" label="Active Background" />
							<yuno-forms-color formControlName="activeFont" label="Active Font" />
						</div>
					</div>
					<!-- OTHER COLORS -->
					<div formGroupName="colors" class="flex flex-col gap-8">
						<section>
							<yuno-forms-title>States</yuno-forms-title>
							<div
								formGroupName="states"
								class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
								<yuno-forms-color formControlName="background" label="Background" />
								<yuno-forms-color formControlName="font" label="Font" />
							</div>
						</section>
						<section>
							<yuno-forms-title>Buttons</yuno-forms-title>
							<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
								<yuno-forms-color formControlName="button" label="Background" />
								<yuno-forms-color formControlName="buttonFont" label="Font" />
							</div>
						</section>
						<section>
							<yuno-forms-title>Map Items</yuno-forms-title>
							<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
								<yuno-forms-color formControlName="panoramas" label="Panoramas" />
								<yuno-forms-color
									formControlName="panoramasClusterCount"
									label="Panoramas Cluster Count" />
								<yuno-forms-color formControlName="measure" label="Measure" />
							</div>
						</section>
						<section>
							<yuno-forms-title>Others</yuno-forms-title>
							<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
								<yuno-forms-color formControlName="additional" label="Compass" />
								<yuno-forms-color
									formControlName="textHeading"
									label="Text Heading" />
								<yuno-forms-color formControlName="textLink" label="Text Link" />
							</div>
						</section>
					</div>
				</form>
			</div>
		</yuno-card>
		<!-- PROJECT CONFIG -->
		<yuno-card>
			<ng-container title>Project Configuration</ng-container>
			<div content class="flex flex-col gap-4">
				<form [formGroup]="service.appForm" class="grid grid-cols-2 gap-4">
					<yuno-forms-text formControlName="_id" label="AppId" />
					<section formGroupName="client">
						<yuno-forms-text formControlName="_id" label="Client Id" />
					</section>
					<yuno-forms-text
						class="col-span-2"
						formControlName="id"
						label="Project Name / Id" />
					<yuno-forms-number
						class="col-span-2"
						formControlName="trackingId"
						label="Matomo Tracking Id" />
					<a
						target="_blank"
						rel="noopener"
						class="col-span-2 -mt-4"
						[href]="getMatomoUrl()">
						Open Matomo
					</a>
				</form>
				<form [formGroup]="service.configForm" class="grid grid-cols-1 gap-1">
					<yuno-forms-text formControlName="path" label="Content Path" />
					<yuno-admin-annotation type="warning">
						Only change this value at the start of the project. All connections to data
						stored on the servers will be lost!
					</yuno-admin-annotation>
				</form>
				<div class="flex flex-row justify-center">
					<button yuno-admin-button color="primary" (click)="openFileUpload()">
						Upload .YUNO
					</button>
				</div>
			</div>
		</yuno-card>
	</div>
}
