@if ({ users: users$ | async }; as data) {
	<yuno-card>
		<section content class="grid grid-cols-1">
			<yuno-admin-table
				[selectable]="false"
				[draggable]="false"
				[filterable]="true"
				[sortable]="true"
				[pagination]="true"
				[pageOptions]="{
					pageSize: 100,
					pageSizeOptions: [5, 10, 25, 100],
					hidePageSize: false
				}"
				[defaultColumn]="'email'"
				[data]="data.users"
				[buttons]="tableButtons"
				[columns]="[
					{ key: 'displayName', label: 'Name' },
					{ key: 'email', label: 'Email' },
					{ key: 'role', label: 'Role' },
					{ key: 'language', label: 'Language' },
					{ key: 'dateLastLogin', label: 'Last Signin', type: 'date' }
				]"
				(clicked)="onSelect($event)">
				<button yuno-admin-button (click)="onSignUser()" [add]="true">Sign Up User</button>
			</yuno-admin-table>
		</section>
	</yuno-card>
}

<ng-template #tableButtons let-row>
	@if (minimalRole(roles.SUPERADMIN)) {
		@if (!row?.email?.includes('@xkp.nl') && !row?.displayName?.includes('Deleted')) {
			<button yuno-admin-button-table color="danger" (click)="onDelete(row)">Delete</button>
		}
	}
</ng-template>
